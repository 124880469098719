import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { motion } from 'framer-motion';

import MetaTags from '../../../components/MetaTags';

import { theme } from '../../../styles/theme';

import Background from '../../../components/Background';
import Header from '../../../components/Header2';
import HowGroupGiftsWork from '../../../sections/occasion/HowGroupGiftsWork';

import CuratedGifts from '../../../sections/occasion/CuratedGifts';

import GroupCard from '../../../sections/occasion/GroupCard';
import LoveIllume from '../../../sections/occasion/LoveIllume';
import JoinOccasions from '../../../sections/JoinOccasions';
import Footer from '../../../sections/common/FooterWithNav';

import data from '../../../sections/occasion/data';
import HeaderSection from '../../../sections/occasion/HeaderSection';
import futureCelebrating from '../../../images/occasion/birthday-boyfriend.png';
import futurePhone from '../../../images/occasion/iPhone12.png';

const Layout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 24px;
  position: relative;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    -webkit-backface-visibility: hidden;
  }
`;

const OccassionBirthdayBoyfriend: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <MetaTags
        title='Occasions | illume'
        description={'Give your boyfriend a meaningful birthday gift and ecard with illume.'}
      />
      <Layout>
        <Background showTopBlob={false} />
        <Header />
        <HeaderSection
          heading={"Celebrate your boyfriend's birthday"}
          paragraphs={[
            'Maybe this is your first year celebrating his birthday or maybe you’re well-versed in the annual cheer. Either way, surprise your sweetie with a meaningful gift from illume!',
            'Pick out the perfect gift from illume’s digital marketplace and then customize a free ecard with a personal note, photos, videos, GIFs, and more. When you’re ready, just hit send :)',
          ]}
          buttonLink={'https://link.illumenotes.com/v1rjqLne6C9gRmtf7'}
          images={{
            celebration: {
              image: futureCelebrating,
              label: 'Birthday for Boyfriend',
            },
            phone: {
              image: futurePhone,
              label: 'Birthday for Boyfriend Mobile',
            },
          }}
        />
        <HowGroupGiftsWork />
        <CuratedGifts gifts={data.gifts} />
        <GroupCard />
        <LoveIllume testimonials={data.testimonials} />
        <JoinOccasions 
            joinMessage={'of celebrating birthdays'}
        />
        <Footer links={data.links} />
      </Layout>
    </ThemeProvider>
  );
};

export default OccassionBirthdayBoyfriend;
